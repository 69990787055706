import LottieEmbedComponent from '../components/LottieEmbed/viewer/LottieEmbed';
import LottieEmbedController from '../components/LottieEmbed/viewer/LottieEmbed.controller';


const LottieEmbed = {
  component: LottieEmbedComponent,
  controller: LottieEmbedController
};


export const components = {
  ['LottieEmbed']: LottieEmbed
};

